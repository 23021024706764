// JQUERY FIX
window.$ = window.jQuery = require('jquery')

// YARN LIBS
// require("turbolinks").start()
require("jquery-lazy")

// CUSTOM LIBS
require("libs/chosen")

// // STIMULUS CONFIG, LOAD ALL CONTROLLERS
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
const application = Application.start()
const context = require.context("controllers", true, /.js$/)
application.load(definitionsFromContext(context))

// $(document).on('turbolinks:before-visit', function() {
//   window.turbolinks_referer = location.href;
// })

// AUTOLOAD EVERY PAGE
// $(document).on('turbolinks:load', function() {
//   $('.js-lazy').Lazy()

//   // FIX GOOGLE & METRIKA
//   if (window.turbolinks_referer) {
//     gtag('config', 'UA-159967020-1', { 'page_title': $('title').html(), 'page_location': location.href })
//     window.yaCounter57319096.hit(location.href, $('title').html(), window.turbolinks_referer);
//   }
// })

$(document).ready(function() {
  $('.js-lazy').Lazy()
})
