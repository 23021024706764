import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'select' ]

  connect() {
    let self = this

    // CLEAR OLD
    $('.chosen-container-single').remove()
    if ($('.insta-select_1').val() == '') {
      $('.insta-select_2').attr('disabled', true)
    }
    $('.insta-select_3').attr('disabled', true)

    // INIT PLUGIN
    $(this.selectTargets).chosen({
      no_results_text: 'Ничего не найдено'
    }).change(function() {
      let type = $(this).attr('data-type')
      let value = $(this).val()
      self.processGo(type, value)
    })
  }

  processGo(type, value) {
    let url = `/api/insta-search?type=${type}&query=${value}`

    $.ajax({
      url: url,
      method: 'get',
      success: function(response) {
        let data = response.data
        let $select

        if (type == 'letter') {
          $select = $('.insta-select[data-type="singer"]')
        } else if (type == 'singer') {
          $select = $('.insta-select[data-type="song"]')
        }

        // CLEAR ALL
        $select.empty()
        $select.append($('<option></option>'))

        // INSERT DATA
        for (let i = 0; i < data.length; i++) {
          let element = data[i]
          $select.append(
            $('<option></option>')
              .attr('value', element[0])
              .text(element[1])
          )
        }

        // RE-INIT
        $select
          .attr('disabled', false)
          .trigger('chosen:updated')
      }
    })
  }

  submit() {
    let slug1 = $('.insta-select_1').val()
    let slug2 = $('.insta-select_2').val()
    let slug3 = $('.insta-select_3').val()
    let slugs = [ slug1, slug2, slug3 ]
    let urls = []

    for (let i = 0; i < slugs.length; i++) {
      if (slugs[i] != '') {
        urls.push(slugs[i])
      }
    }

    location = urls.join('/')
  }
}